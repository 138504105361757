<template>
    <div >
        <!-- <NavMenutitle /> -->
        <!-- 主页导航栏 -->
        <!-- <NavMenu /> -->
        <!-- 主页大图 -->
        <Large />
        <!-- 主页总部浏览图 -->
<!--        <Largeimg />-->
      <div style="width: 100%;background: #ffffff;position: relative;margin-bottom: 50px">
        <div class="title" >
          <h2>中国功能性纱线工业互联网平台</h2>
<!--          <div style="position: absolute;top: 50px;left: calc(50% - 30px);">-->
            <div style="width: 60px;background: #193D84;height: 3px;margin:0 auto;margin-top: 1vh"></div>
<!--          </div>-->
          <p style="padding-top: 10px">重点加强公共服务属性，国绕产业生态、新智造、共性技术三大方向，建设全省的纱线产业互联网平台</p>
        </div>
        <img src="../../assets/imgs/body2.jpg" style="transition: all 1s linear;" alt="" width="100%">

      </div>
        <!-- 解决方案 -->
        <Solution />
        <!-- 新闻咨询 -->
        <News />
        <!-- 互联网平台图 -->
        <Terrace />
        <!-- 合作伙伴图 -->
        <Cooperate />
        <!-- <ListContainer /> -->
      <Footer   style="margin-bottom: 110px"/>

    </div>
</template>

<script>
    import Footer from '@/components/Footer/index.vue'
    import ListContainer from './ListContainer'
import NavMenu from './NavMenu/index.vue'
import Large from './Large/index.vue'
import Largeimg from './Largeimg/index.vue'
import Cooperate from './Cooperate/index.vue'
import Terrace from './Terrace/index.vue'
import News from './News/index.vue'
import NavMenutitle from './NavMenutitle/index.vue'
import Solution from './Solution/index.vue'
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Home',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    ListContainer,
    // eslint-disable-next-line vue/no-unused-components
    NavMenu,
    Large,
    Largeimg,
    Cooperate,
    Terrace,
    News,
      Footer,
    // eslint-disable-next-line vue/no-unused-components
    NavMenutitle,
    Solution
  },
    data () {
        return {
            activeName: '1',
            top: this.$scrollTop,
            isShow: false
        }
    },
    watch:{
        top:{
            handler(newV,oldV) {
                console.log('top',newV.value)
                let top = newV.value
                if(top>500){
                    // let val = 500-300
                    this.isShow = true
                }
            },
            deep: true
        },
    },
    mounted() {

    }
}
</script>

<style lang="less" scoped>
div {
    width: 100%;
}
  .show{
    position: inherit;
    left: 0!important;
  }
.title {
  position: absolute;
  left: 0;
  top: 5vh;
  h2 {
    margin: auto;
    width: 100%;

    height: 41px;
    line-height: 41px;
    text-align: center;
    color: rgba(51, 51, 51, 1);
    font-size: 28px;
    font-family: SourceHanSansSC-regular;
  }

  p {
    height: 35px;
    line-height: 35px;
    padding-top: 9px;
    color: rgba(136, 136, 136, 1);
    font-size: 16px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
  }
}
  /deep/.title{
    p{
      font-size: 18px!important;
    }
  }
</style>
