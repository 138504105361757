var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"footer"},[_c('div',{staticClass:"footer-container"},[_c('div',{staticClass:"footerList"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"footerItem"},[_c('ul',{staticClass:"footerItemCon"},[_c('h4',[_vm._v("子站连接")]),_vm._m(3),_vm._m(4),_vm._m(5),_c('li',[_c('router-link',{attrs:{"to":{path:'/Build'}}},[_vm._v("生态服务云平台")])],1),_c('li',[_c('router-link',{attrs:{"to":{path:'/Build'}}},[_vm._v("服装行业产业大脑")])],1)])]),_c('div',{staticClass:"footerItem"},[_c('ul',{staticClass:"footerItemCon"},[_c('h4',[_vm._v("解决方案")]),_c('li',[_c('router-link',{attrs:{"to":{path:'/Build'}}},[_vm._v("纺织行业智能制造解决方案")])],1),_c('li',[_c('router-link',{attrs:{"to":{path:'/Build'}}},[_vm._v("印染行业智能制造解决方案")])],1),_c('li',[_c('router-link',{attrs:{"to":{path:'/Build'}}},[_vm._v("服装行业智能制造解决方案")])],1),_c('li',[_c('router-link',{attrs:{"to":{path:'/Build'}}},[_vm._v("产业用纺织品解决方案")])],1),_c('li',[_c('router-link',{attrs:{"to":{path:'/Build'}}},[_vm._v("面料贸易解决方案")])],1)])])]),_vm._m(6)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('ul',{staticClass:"footerItemCon"},[_c('img',{staticStyle:{"width":"155px","margin":"20px 0"},attrs:{"src":require("../../assets/imgs/logo.png"),"alt":""}}),_c('li',{staticStyle:{"color":"white"}},[_vm._v("咨询热线：0534-5040001")]),_c('li',{staticStyle:{"color":"white"}},[_vm._v("服务邮箱：jwzhilian@126.com")]),_c('li',{staticStyle:{"color":"white"}},[_vm._v("服务时间：工作日09:00-17:30")]),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"space-between","margin-top":"20px"}},[_c('div',{staticStyle:{"width":"100px"}},[_c('img',{staticStyle:{"width":"100px","height":"100px"},attrs:{"src":require("./images/erweima.png"),"alt":""}}),_c('div',{staticStyle:{"text-align":"center","width":"100px","font-size":"12px","line-height":"25px"}},[_vm._v("经纬智联公众号")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('ul',{staticClass:"footerItemCon"},[_c('h4',[_vm._v("友情链接")]),_c('li',[_c('a',{attrs:{"href":"http://www.sdfx.cn/","target":"_blank"}},[_vm._v("山东省纺织服装行业协会")])]),_c('li',[_c('a',{attrs:{"href":""}},[_vm._v("山东凌锐信息科技有限公司")])]),_c('li',[_c('a',{attrs:{"href":"http://www.yjkjgs.com","target":"_blank"}},[_vm._v("远景云平台")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"footerItem"},[_c('ul',{staticClass:"footerItemCon"},[_c('h4',[_vm._v("快速入口")]),_c('li',[_c('a',{attrs:{"href":"https://xiajin-mall.jwzhilian.com/buy/","target":"_blank"}},[_vm._v("供需中心")])]),_c('li',[_c('a',{attrs:{"href":"https://xiajin-mall.jwzhilian.com/sell/","target":"_blank"}},[_vm._v("平台产品")])]),_c('li',[_c('a',{attrs:{"href":"https://xiajin-mall.jwzhilian.com/quote/","target":"_blank"}},[_vm._v("市场行情")])]),_c('li',[_c('a',{attrs:{"href":"https://xiajin-mall.jwzhilian.com/news/","target":"_blank"}},[_vm._v("行业资讯")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://jinrong.jwzhilian.com","target":"_blank"}},[_vm._v("金融服务云平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"https://xiajin-mall.jwzhilian.com/job/","target":"_blank"}},[_vm._v("人才赋能云平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('a',{attrs:{"href":"http://keji.jwzhilian.com","target":"_blank"}},[_vm._v("科技创新云平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"copyright"},[_c('p',[_vm._v("版权所有 ©经纬智联（山东）数字科技有限公司 备案/许可证号:鲁ICP备2023017400号-1")])])
}]

export { render, staticRenderFns }