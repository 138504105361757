<template>
    <div>
        <!-- 头部 -->
        <header class="header">
            <!-- 头部的第一行 -->
            <div class="top">
                <div class="container">
                    <div class="loginList">
                        <img src="@/assets/imgs/logo.png" alt="">
                    </div>
                    <div class="typeList">
                        <router-link to="/Home">首页</router-link>
                        <a href="###">供需中心</a>
                        <a href="###">平台产品</a>
                        <a href="###">市场行情</a>
                        <a href="###">政策速递</a>
                        <a href="###">行业咨询</a>
                        <a href="###">知识库</a>
                        <a href="###">企业服务</a>
                    </div>
                </div>
            </div>
            <!--头部第二行 搜索区域-->
            <div class="bottom">
                <router-link title="" to="">
                    登录控制台
                </router-link>
                <router-link title="" class="zhuce" to="">
                    立即注册
                </router-link>
            </div>
        </header>
    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Header',
  methods: {
    toSearch () {
      // push和replace方法在使用的时候 都需要加第二个和第三个函数
      // 两个都是回调函数
      // 第一个函数 跳转成功以后执行的回调
      // 第二个函数 跳转失败以后执行的回调
      this.$router.push('/search')
    }
  }
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

.header {
    width: 100%;
    height: 90px;
    &>.top {
        //   background-color: #eaeaea;
        height: 30px;
        line-height: 30px;

        .container {
            margin: auto;
            overflow: hidden;

            .loginList {
                float: left;

                p {
                    float: left;
                    margin-right: 10px;

                    .register {
                        border-left: 1px solid #b3aeae;
                        padding: 0 5px;
                        margin-left: 5px;
                    }
                }
            }

            .typeList {
                margin-top: 30px;
                text-decoration-line: none;

                a {
                    padding: 20px;
                    text-decoration-line: none;
                    color: black;
                    font-size: 15px;
                }
            }
        }
    }

    // 登录注册模块
    .bottom {
        float: right;
        padding-right: 20px;
        margin-top: 5px;
        // text-decoration-line: none;
    }

    .bottom router-link p{
        padding: 20px;
        font-size: 15px;
        text-decoration-line: none;
    }
    .bottom .zhuce{
        width: 155px;
        height: 100px;
        // background-color: #2C5CE5;
        // color: white;
        text-decoration-line: none;
    }

}
</style>
