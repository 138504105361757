<template>
  <div>
    <NavMenutitle style="z-index: 999;position: fixed;left: 0;top: 0;width: 100%;"/>
    <div style="width: 60%;margin-left: 20%;background: #f7f7f7;margin-top: 77px;padding: 30px 80px 50px">
      <div style="margin-top: 20px;font-size: 30px;font-weight: bold;color: #41a4f1">· 纺纱行业智能制造 ·</div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺织服装行业生产管理</div>
        <div>利用Gongqi
          Designer技术实现销售、采购、设计、库存、生产、仓库、委外、财务/绩效等内部流程的体系管理，解决产业价值链与缝制和终端销售环节，面料制衣的产能匹配是实现供应链快速反应的难点
        </div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺纱工艺管理管理</div>
        <div>按纱品种提供对配棉工艺的过程数据记录和总结，实现对影响纱质量的关键因素进行分析与报告生成</div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺纱MES系统</div>
        <div>实时反映企业运行状态，将采集的数据形象化、直观化、具体化</div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺织服装行业生产管理</div>
        <div>利用Gongqi
          Designer技术实现销售、采购、设计、库存、生产、仓库、委外、财务/绩效等内部流程的体系管理，解决产业价值链与缝制和终端销售环节，面料制衣的产能匹配是实现供应链快速反应的难点
        </div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺纱工艺管理管理</div>
        <div>按纱品种提供对配棉工艺的过程数据记录和总结，实现对影响纱质量的关键因素进行分析与报告生成</div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺纱MES系统</div>
        <div>实时反映企业运行状态，将采集的数据形象化、直观化、具体化</div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺织服装行业生产管理</div>
        <div>利用Gongqi
          Designer技术实现销售、采购、设计、库存、生产、仓库、委外、财务/绩效等内部流程的体系管理，解决产业价值链与缝制和终端销售环节，面料制衣的产能匹配是实现供应链快速反应的难点
        </div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺纱工艺管理管理</div>
        <div>按纱品种提供对配棉工艺的过程数据记录和总结，实现对影响纱质量的关键因素进行分析与报告生成</div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺纱MES系统</div>
        <div>实时反映企业运行状态，将采集的数据形象化、直观化、具体化</div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺织服装行业生产管理</div>
        <div>利用Gongqi
          Designer技术实现销售、采购、设计、库存、生产、仓库、委外、财务/绩效等内部流程的体系管理，解决产业价值链与缝制和终端销售环节，面料制衣的产能匹配是实现供应链快速反应的难点
        </div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺纱工艺管理管理</div>
        <div>按纱品种提供对配棉工艺的过程数据记录和总结，实现对影响纱质量的关键因素进行分析与报告生成</div>
      </div>
      <div style="margin-top: 20px">
        <div style="font-size: 18px;font-weight: bold;margin-bottom: 5px">纺纱MES系统</div>
        <div>实时反映企业运行状态，将采集的数据形象化、直观化、具体化</div>
      </div>
    </div>
    <Footer style="z-index: 999"/>
  </div>
</template>

<script>
import NavMenutitle from '../Home/NavMenutitle/index.vue'
import Footer from '@/components/Footer/index.vue'

export default {
  components: {
    NavMenutitle,
    Footer
  },
  data() {
    return {}
  },
  mounted() {
    // this.$route.query.param
  },
  methods: {

  }
}
</script>

<style lang="less">

</style>
