<template>
    <div class="box1">
        <div class="box">
            <img src="../../../assets/imgs/logo.png" alt="">
<!--            <span class="title">纺织服装产业云平台</span>-->
        </div>
<!--        <span class="stat
e">声明: <span>本网站部分资源及图片来源网络，如有侵权，请随时联系站长删除！</span></span>-->
      <NavMenu  style="margin-left: 10px;"/>

      <div class="box2">
        <div class="login" @click="toZX">在线咨询</div>
          <div class="login" @click="toBottom">联系我们</div>
<!--          <div><router-link to="/Register" style="text-decoration:none;color: black;"><div class="login" >注册</div></router-link></div>-->
            <div> <router-link to="/Login" style="text-decoration:none;color: black;"><div  class="login" style="border: 0">登录</div></router-link></div>
        </div>
    </div>
</template>

<script>
    import NavMenu from '../NavMenu/index.vue'

    export default {
        components: {
            NavMenu
        },
  data () {
    return {
      state: ''
    }
  },
  methods: {
    querySearch () { },
    handleSelect () { },
    handleIconClick () { },
      toBottom(){
          this.$nextTick(() => {
              let scrollElem = this.$scrollDiv.value;
              console.log('scrollElem',scrollElem.scrollHeight)
              scrollElem.scrollTo({ top: scrollElem.scrollHeight, behavior: 'smooth' });
          });
      },
    toZX() {
      window.open('https://w102.ttkefu.com/k/linkurl/?t=7D3JJC3')
    }
  }
}
</script>

<style lang="less" scoped>
  .box{
    img{
      margin-left: 100px;
      width:12rem;
      height: 3.5rem;
      transform: scale(.9);
    }
  }
.box1 {
    height: 8vh;
    width: 100%;
    /*margin-bottom: 10px;*/
    display:flex;
    justify-content: space-between;
    align-items:center;
  background: linear-gradient(to right, rgba(17,41,90,1), rgba(17,41,90,0.7));

  ::v-deep .el-input__inner{
    background-color: transparent;

}
}

.state {

    display: block;
    width: 65%;
    padding-top: 5px;
    overflow: hidden;
    color: rgba(86, 238, 230, 1);
    font-size: 12px;
    text-align: left;
    font-family: SourceHanSansSC-regular;

    span {
        color: #b4b4b4;
    }
}

.title {
    display: block;
    font-size: 18px;
    text-align: center;
    margin-top: 15px;
    width: 200px;
    height: 40px;
    float: left;
    color: #fff;
}

.box2 {
    float: right;
    right: 0px;
    /*margin-top: -38px;*/
    display: flex;
    align-items: center;
    .register{
      height: 50px;background-color: rgba(15, 64, 245, 1);color: rgba(255, 255, 255, 1);
      line-height: 50px;
      text-align: center;
      width: 100px;
      font-family: SourceHanSansSC-regular;
      transition: all .5s linear;
      font-size: 14px;
    }
    .login{
      font-weight: bold;
      cursor: pointer;
      margin-top: .5rem;
      height: 20px;
      line-height: 20px;
      text-align: center;
      width: 90px;
      color:#EFF7F8;
      /*font-family: SourceHanSansSC-regular;*/
      transition: all .5s linear;
      font-size: 16px;
      //border-right: 1px solid #EFF7F8;
    }
  .register:hover,.login:hover{
    font-weight: bold;
    font-size: 20px;

  }
    .autocomplete {
        margin-right: 20px;
    }

    span {
        padding-right: 20px;
        color: #fff;
    }
}

.my-autocomplete {
    li {
        line-height: normal;
        padding: 7px;

        .name {
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .addr {
            font-size: 12px;
            color: #b4b4b4;
        }

        .highlighted .addr {
            color: #ddd;
        }
    }
}


</style>
