<template>
    <div class="terrace" style="position: relative">
        <p style="color: rgba(51, 51, 51, 1);
      font-size: 28px;
      text-align: center;
      font-family: SourceHanSansSC-regular;
      font-weight: bold;">建设国内功能性纱线工业互联网平台</p>
      <div style="position: absolute;top: 80px;left: calc(50% - 30px);">
        <div style="width: 60px;background: #193D84;height: 3px;margin:0 auto"></div>
      </div>
        <!-- <p class="Subtitle">依托特色纱线数字经济总部，让“夏津纱线”走向全国</p> -->
      <div style="position: absolute;width:100%;bottom: 50px;top: 150px" class="map">
        <dv-flyline-chart-enhanced :config="config" :dev="true" style="width:100%;height:100%;" />
      </div>
    </div>
</template>

<script>
  import dian from './images/dian.jpg'
export default {
  data(){
      return{
          config:{
              orbitColor:'rgba(182,198,76,0.1)',
              flylineColor:'rgba(182,198,76,1)',
              lineWidth:3,
              curvature:5,
              line:{
                  radius:500
              },
              // pointsImg:{url:dian,width:25,height:25},
              // coordinate: [0.45,0.40],
              icon: {
                  show: false,
                  src: dian,
                  width:25,
                  height:25
              },
              text: {
                  show: false,
              },
              points:[
                  {"name":"夏津","coordinate":[0.652, 0.453],
                      halo: {
                          show: true,
                          radius: 100,
                      },
                  },
                  {"name":"新疆","coordinate":[0.322, 0.32]},
                {"name":"西藏","coordinate":[0.32, 0.582]},
                {"name":"青海","coordinate":[0.422, 0.48]},
                {"name":"四川","coordinate":[0.5, 0.62]},
                {"name":"甘肃","coordinate":[0.515, 0.505]},
                {"name":"云南","coordinate":[0.48, 0.81]},
                {"name":"宁夏","coordinate":[0.538, 0.45]},
                {"name":"内蒙古","coordinate":[0.556, 0.353]},
                {"name":"陕西","coordinate":[0.565, 0.538]},
                {"name":"重庆","coordinate":[0.56, 0.658]},
                {"name":"贵州","coordinate":[0.555, 0.745]},
                {"name":"广西","coordinate":[0.57, 0.825]},
                {"name":"山西","coordinate":[0.605, 0.43]},
                {"name":"河南","coordinate":[0.62, 0.535]},
                {"name":"湖北","coordinate":[0.61, 0.61]},
                {"name":"湖南","coordinate":[0.605, 0.705]},
                {"name":"广东","coordinate":[0.633, 0.81]},
                {"name":"河北","coordinate":[0.631, 0.378]},
                {"name":"北京","coordinate":[0.648, 0.382]},
                {"name":"天津","coordinate":[0.657, 0.393]},
                {"name":"安徽","coordinate":[0.667, 0.585]},
                {"name":"江西","coordinate":[0.655, 0.69]},
                {"name":"江苏","coordinate":[0.7, 0.56]},
                {"name":"上海","coordinate":[0.72, 0.6]},
                {"name":"浙江","coordinate":[0.705, 0.65]},
                {"name":"福建","coordinate":[0.686, 0.73]},
                {"name":"辽宁","coordinate":[0.715, 0.3]},
                {"name":"吉林","coordinate":[0.734, 0.23]},
                {"name":"黑龙江","coordinate":[0.74, 0.125]},
                  ],
              lines:[
                  {
                      source: "夏津",
                      target: "新疆",
                      width:3,
                      orbitColor:'rgba(233,85,60,0.1)',
                      color:'rgba(182,198,76,1)',
                  },
                {
                  source: "夏津",
                  target: "西藏",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "青海",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "四川",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "甘肃",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "云南",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "宁夏",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "内蒙古",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "陕西",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "重庆",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "贵州",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "广西",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "山西",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "河南",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "湖北",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "湖南",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "广东",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "河北",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "北京",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "天津",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "安徽",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "江西",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "江苏",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "上海",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "浙江",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "福建",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "辽宁",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "吉林",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
                {
                  source: "夏津",
                  target: "黑龙江",
                  width:3,
                  orbitColor:'rgba(233,85,60,0.1)',
                  color:'rgba(182,198,76,1)',
                },
              ],
          }

      }
  },
    created(){

      this.config.points.map(item=>{
          // item.text = ""
      })
      // for(var i in json){
      //     this.config.points.push(
      //         {
      //             text:i,
      //             position:json[i]
      //         }
      //     )
      // }
      // console.log(JSON.stringify(this.config.points))
    },
}
</script>

<style lang="less" scoped>
  .terrace {
    position: relative;
    background: url(../../../assets/imgs/map/back.jpg);
    height: 100vh;
    background-size: 100% 100%;
    background-repeat:no-repeat ;

    p {
        margin: auto;
        padding-top: 30px;
        width: 1200px;
        height: 41px;
        color: rgba(51, 51, 51, 1);
        font-size: 28px;
        text-align: center;


    }

    .Subtitle {
        text-align: center;
        margin-top: 30px;
        width: 1200px;
        height: 35px;
        color: rgba(136, 136, 136, 1);
        font-size: 18px;
    }
    .map{
      background: url(../../../assets/imgs/map/map.png);
      width: 60%;
      background-position: center center;
      background-size: 60% 100%;
      background-repeat:no-repeat ;

    }
}
</style>
