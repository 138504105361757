<template>
    <div class="box" style="position: relative">
        <p style=" color:#505050;
      font-size: 32px;
      text-align: center;
      font-family: SourceHanSansSC-regular;
      font-weight: bold;">合作伙伴</p>
      <div style="position: absolute;top: 80px;left: calc(50% - 30px);">
        <div style="width: 60px;background: #193D84;height: 3px;margin:0 auto"></div>
      </div>
        <p class="Subtitle">与100000+企业用户共同成长</p>
        <div class="boximg"><img src="../../../assets/imgs/qiye2.jpg" alt=""></div>
      <div class="BottomTitle">众多企业选择我们，用实力完成客户托付</div>

    </div>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Cooperate'
}
</script>

<style lang="less" scoped>
.box {
    width: 100%;
  padding-bottom: 40px;
}

p {
    margin: auto;
    padding-top: 30px;
    width: 100%;
    height: 41px;
    color: rgba(51, 51, 51, 1);
    font-size: 28px;
    text-align: center;

}

.BottomTitle{
  margin: auto;
  width: 100%;
  height: 41px;
  color: rgba(51, 51, 51, 1);
  font-size: 24px;
  text-align: center;
}
.Subtitle {
    text-align: center;
    margin-top: 30px;
    width: 1200px;
    height: 35px;
    color: rgba(136, 136, 136, 1);
    font-size: 18px;
}

.boximg {
    text-align: center;
    overflow: hidden;
    margin-top: 30px;
  padding-bottom: 2rem;
  padding-left: 40px;

  img{
    width: 1200px;
  }

}
</style>
