<template>
    <div class="ListEl">
        <!-- 我们的服务模块 -->
        <div class="product">
            <div class="producttitle">
                <h2>我们的服务</h2>
                <p>为纺织服装行业及其上下游企业提供服务</p>
            </div>

            <div class="productbox">

                <div class="box1">
                    <p class="productleftboxtitle">
                        纺织服装金融云平台
                    </p>
                    <p class="productleftboxcontent">供应链金融应用，为从业企业用户提供供应链普惠服务，解决小企业资金周转，资金回笼问题，可促进资本尽快投入再生产。
                    </p>
                </div>

                <div class="boxkuai">
                    <div class="box2"><span class="productleftboxtitle">纺织服装产业科技创新平台</span><br>
                        <span class="productleftboxcontent">“产、学、研、创”共享式技术技能创新平台</span>
                        <br>
                    </div>
                    <div class="box3"><span class="productleftboxtitle">纺织服装产业大脑</span><br>
                        <span class="productleftboxcontent">形成“数字经济总部+未来工厂”的发展格局</span>
                        <br>
                    </div>

                </div>

                <div class="boxkuai2">
                    <div class="box4"><span class="productleftboxtitle">纺织服装产业生态服务平台</span><br>
                        <span class="productleftboxcontent">建设“平台+应用+生态+数据”的纺织服装产业生态服务平台</span>
                        <br>
                    </div>
                    <div class="box5"><span class="productleftboxtitle">纺织服装产业人才赋能平台</span><br>
                        <span class="productleftboxcontent">建立学院资源库和企业需求库将企业人才需求和培训机构提供的人才精准匹配</span>
                        <br>
                    </div>
                </div>
            </div>
        </div>

        <!-- 主页大图黑色版 -->
        <div class="Large">
            <img src="./images/主页大图黑色版人型图.png" alt="">
            <div class="larfebigbox">
                <div class="larfeboxtitle"><span>—</span>为用户提供多行业解决方案</div>
                <div class="larfeboxcontent">
                    <div class="larfeboxlogo">
                        <div class="title">纺织行业智能制造解决方案</div>
                        <div class="content">
                            纺织行业智能制造解决方案依据多年的纺织管理经验，为多行业赋能：毛纺、棉纺、化纤、针织、梭织、经编织造、经编织染、针织织染、色织牛仔等，给纺织各细分领域进行赋能、创新和转型。
                        </div>
                    </div>
                </div>
                <div class="larfeboxcontent">
                    <div class="larfeboxlogo2">
                        <div class="title">纺织行业智能制造解决方案</div>
                        <div class="content">
                            纺织行业智能制造解决方案依据多年的纺织管理经验，为多行业赋能：毛纺、棉纺、化纤、针织、梭织、经编织造、经编织染、针织织染、色织牛仔等，给纺织各细分领域进行赋能、创新和转型。
                        </div>
                    </div>
                </div>
                <div class="larfeboxcontent">
                    <div class="larfeboxlogo3">
                        <div class="title">纺织行业智能制造解决方案</div>
                        <div class="content">
                            纺织行业智能制造解决方案依据多年的纺织管理经验，为多行业赋能：毛纺、棉纺、化纤、针织、梭织、经编织造、经编织染、针织织染、色织牛仔等，给纺织各细分领域进行赋能、创新和转型。
                        </div>
                    </div>
                </div>

            </div>

        </div>
        <!-- 平台产品模块 -->
        <div class="product">
            <div class="producttitle">
                <h2>平台产品</h2>
                <p>专为企业用户打造的纺织软件和服务商城</p>
            </div>

            <div class="productboxfuben">

                <div class="box1">
                    <p class="productleftboxtitle">
                        纺织服装行业生产管理系统
                    </p>
                    <span class="productleftboxcontent">
                        纺织服装行业生产管理系统，利用Gongqi
                        Designer设计平台技术实现销售、采
                        购、设计、库存、生产、仓库、委外、
                        财务/绩效等内部流程的体系管理，解决纺
                        织制造产业价值链从上至下分别为原材料、
                        纺纱、织布、印染、裁剪与缝制和终端销售环节
                        ，对于品牌而言，面料厂商和制衣厂商的产能匹配是实现供应链快速反应的难点。
                    </span>
                </div>

                <div class="boxkuai">
                    <div class="box2"><span class="productleftboxtitle">天富纺织印染智能工厂-能耗</span><br>
                        <span class="productleftboxcontent">提供纺织印染能耗仪表联网功能，帮助企业实现远程抄表功能，为企业节能减排以及人员KPI提供数据支持。</span>
                        <br>
                        <span class="manage">企业管理 </span><span class="manage">生产制造</span>
                    </div>
                    <div class="box3"><span class="productleftboxtitle">天富纺织印染智能工厂-印染</span><br>
                        <span class="productleftboxcontent">实时监控设备运行、工艺执行和用能情况，事中预警及事后工艺执行偏差，增加一次染色成功率。</span>
                        <br>
                        <span class="manage">企业管理 </span><span class="manage">生产制造</span>
                    </div>

                </div>

                <div class="boxkuai2">
                    <div class="box4"><span class="productleftboxtitle">天富纺织印染智能工厂-梭织</span><br>
                        <span class="productleftboxcontent">实时监控设备运行情况，实现梭织织机现场可视化管理，辅助企业生产计划排程和订单进度跟踪，统计工人产量和KPI考核。</span>
                        <br>
                        <span class="manage">企业管理 </span><span class="manage">生产制造</span>
                    </div>
                    <div class="box5"><span class="productleftboxtitle">天富纺织印染智能工厂-纬编</span><br>
                        <span class="productleftboxcontent">实时监控圆机产量、异常情况，实现圆机现场可视化管理，辅助企业生产计划排程和订单进度跟踪，统计工人产量和KPI考核。</span>
                        <br>
                        <span class="manage">企业管理 </span><span class="manage">生产制造</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- 最新咨询模块 -->
        <h2>最新资讯</h2>
        <p>与100000+企业用户共同成长</p>
        <div class="news">
            <div class="newspage">
                <img src="./images/咨询图片.png" alt="">
                <p>2023年第一批高新区先进纺织服装企业政策扶助资金申报</p>
                <li>纺织服装企国家新一代自主安全计算系统产业集群...</li>
            </div>
            <div class="newspage">
                <img src="./images/咨询图片.png" alt="">
                <p>2023年第一批高新区先进纺织服装企业政策扶助资金申报</p>
                <li>纺织服装企国家新一代自主安全计算系统产业集群...</li>
            </div>
            <div class="newspage">
                <img src="./images/咨询图片.png" alt="">
                <p>2023年第一批高新区先进纺织服装企业政策扶助资金申报</p>
                <li>纺织服装企国家新一代自主安全计算系统产业集群...</li>
            </div>
        </div>
        <!-- 合作伙伴模块 -->
        <div>
            <h2>合作伙伴</h2>
            <p>实时获取最新的行业资讯</p>
        </div>
        <div class="cooperate">
            <img class="CooperatePage" src="./images/合作伙伴.png" alt="">
        </div>

    </div>
</template>

<script>
export default {
  name: 'ListEl'
}
</script>

<style lang="less" scoped>
* {
    margin: 0;
    padding: 0;
}

// 主页黑色大图部位
.Large {
    background: url(images/主页大图黑色版.png);
    height: 800px;

    img {
        margin-left: 200px;
        // padding-bottom: 400px;
        height: 800px;
        width: 500px;
    }

    .larfebigbox {
        .larfeboxcontent {
            width: 500px;
            height: 130px;
            // background-color: red;
            margin-left: 50px;
            margin-bottom: 100px;
        }

        float: right;
        margin-right: 180px;
        width: 600px;
        height: 800px;
        // background-color: pink;

        .larfeboxtitle {
            color: rgba(173, 168, 186, 1);
            margin-top: 20px;
            margin-left: 50px;
            margin-bottom: 50px;
        }

        .larfeboxlogo {
            width: 60px;
            height: 60px;
            background: url(./images/主页大图logo.png);
            margin-left: 10px;
            // display: block;

        }

        .larfeboxlogo2 {
            width: 60px;
            height: 60px;
            background: url(./images/主页大图logo2.png);
            margin-left: 10px;
            // display: block;

        }

        .larfeboxlogo3 {
            width: 60px;
            height: 60px;
            background: url(./images/主页大图logo3.png);
            margin-left: 10px;
        }

        .title {
            margin-left: 80px;
            width: 250px;
            // background-color: aqua;
            color: rgba(255, 255, 255, 1);
            font-size: 20px;
        }

        .content {
            margin-top: 20px;
            margin-left: 80px;
            width: 400px;
            // height: 100px;
            // background-color: aqua;
            color: rgba(173, 168, 186, 1);
        }

    }

}

.CooperatePage {
    margin-left: 160px;
}

h2 {
    padding-top: 50px;
    width: 100%;
    height: 50px;
    color: rgba(47, 54, 76, 1);
    font-size: 36px;
    text-align: center;
    font-family: AlibabaPuHui-bold;
}

p {
    width: 100%;
    height: 34px;
    color: rgba(102, 102, 102, 1);
    font-size: 14px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
    margin-top: 20px;
}

.news {
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: space-between;

}

.news .newspage {
    width: 386px;

    // background-color: pink;
    p {
        font-size: 18px;
        // margin-top: 50px;
    }

    li {
        margin-top: 20px;
    }
}

.cooperate {
    img {
        width: 1200px
    }

    ;
    background: url(images/合作伙伴背景图.png);
}

.product {
    height: 770px;
    background: url(images/平台产品背景图.png);

    .producttitle {
        height: 100px;
        width: 480px;
        margin: auto;
        margin-bottom: 100px;
        // margin-top: 100px;
        // background-color: aqua;
    }

    .productbox {
        display: block;
        // margin-top: 50px;
        margin: auto;
        width: 1200px;
        height: 380px;
        background-color: pink;
    }
    .productboxfuben {
        display: block;
        // margin-top: 50px;
        margin: auto;
        width: 1200px;
        height: 380px;
        background-color: pink;
        .box1 {
        background: url(images/背景图1.png);
        width: 360px;
        height: 380px;
        float: left;

        .productleftboxtitle {
            font-size: 24px;
            color: rgba(24, 35, 46, 100);
            margin-bottom: 10px;
        }

        .productleftboxcontent {
            width: 300px;
            margin: 15px auto;
            // background-color: pink;
            font-size: 18px;
            color: #60646A 100%;
        }

    }
    }
    .box1 {
        background: url(images/背景图1.png);
        width: 360px;
        height: 380px;
        float: left;

        .productleftboxtitle {
            font-size: 24px;
            color: rgba(24, 35, 46, 100);
            margin-bottom: 10px;
        }

        .productleftboxcontent {
            width: 300px;
            margin: 15px auto;
            // background-color: pink;
            font-size: 18px;
            color: #60646A 100%;
        }

    }

    .boxkuai {
        width: 420px;
        height: 380px;
        float: right;

        .manage {
            font-size: 12px;
            padding: 5px;
            border: 1px solid #60646A;
            margin-left: 10px;
        }

        .productleftboxtitle {
            font-size: 24px;
            color: rgba(24, 35, 46, 100);
        }

        .productleftboxcontent {
            font-size: 18px;
            color: #60646A 100%;

        }

        .box2 {
            // float: left;
            background: url(images/矩形背景图.png);
            width: 420px;
            height: 190px;
            border-left: 1px solid grey;
            border-bottom: 1px solid grey;
        }

        .box3 {
            background: url(images/矩形背景图.png);
            width: 420px;
            height: 190px;
            border-left: 1px solid grey;
        }
    }

    .boxkuai2 {
        width: 420px;
        height: 380px;
        float: right;
        background-color: aqua;

        .manage {
            font-size: 12px;
            padding: 5px;
            border: 1px solid grey;
            margin-left: 10px;
        }

        .productleftboxtitle {
            font-size: 24px;
            color: rgba(24, 35, 46, 100);
            margin: 20px;
        }

        .productleftboxcontent {
            font-size: 18px;
            color: #60646A 100%;
            margin: 20px;
        }

        .box4 {
            background: url(images/矩形背景图.png);
            height: 190px;
            width: 420px;
            border-bottom: 1px solid grey;
        }

        .box5 {
            background: url(images/矩形背景图.png);
            height: 190px;
            width: 420px;
        }
    }
}

</style>
