<template>
  <div class="swiper-bg">
    <div class="content">
      <NavMenutitle />


    </div>

<!--    <div style="position: absolute;top:5rem;left: 8%;width: 84%;height: 70vh;opacity: 0;transition: all 1s linear;z-index: 99"  :class="actIndex == 0 ? 'opctiy1':''">-->
<!--      <div style="color: white;margin: 10vh;font-size: 1.5rem;line-height: 3rem;margin-top: 20vh">-->
<!--        <h3 style="font-size: 3.6rem;font-family:'Al Bayan';line-height: 8rem">纺织服装产业云平台</h3>-->
<!--        <div>建设纺织服装产业链数字经济总部平台</div>-->
<!--        <div>为纺织服装行业及其上下游企业提供服务</div>-->
<!--      </div>-->
<!--    </div>-->

<!--    <div style="position: absolute;top:5rem;left: 8%;width: 84%;height: 70vh;opacity: 0;transition: all 1s linear;z-index: 99"  :class="actIndex == 1 ? 'opctiy1':''">-->
<!--      <div style="color: white;margin: 10vh;font-size: 1.5rem;line-height: 3rem;margin-top: 20vh">-->
<!--        <h3 style="font-size: 3.6rem;font-family:'Al Bayan';line-height: 8rem">建设山东省纺织服装产业链数字经济总部</h3>-->
<!--        <div>重点加强公共服务属性</div>-->
<!--        <div>围绕产业生态、新制造、共性技术三大方向</div>-->
<!--        <div>建设全省的纺织服装产业互联网平台</div>-->
<!--      </div>-->
<!--    </div>-->



    <div class="swiper">
      <el-carousel @mouseenter.native="delHandleMouseEnter()" ref="carousel" height="100vh" @change="imgChange" :interval="4500" trigger="click" arrow="never" :loop="true">
        <el-carousel-item v-for="(item,i) in Image" :key="item">
          <img :src="item" alt="" :class="actIndex == i ? 'actImg':''" ref="imgs" style="transform: scale(1.2);transition:all 4s linear">
        </el-carousel-item>
      </el-carousel>
      <div class="list" >
        <SwiperLsit />
      </div>
    </div>

  </div>
</template>

<script>
import NavMenutitle from '../NavMenutitle/index.vue'
import SwiperLsit from '../SwiperList/index.vue'


export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Large',
  components: {
    NavMenutitle,
    SwiperLsit
  },
  data () {
    return {
        actIndex:-1,
      Image: [
        require('../../../assets/imgs/swiper/banner1.jpg'),
        require('../../../assets/imgs/swiper/banner2.jpg'),
        require('../../../assets/imgs/swiper/banner3.jpg'),
      ]
    }
  },
    mounted(){
      setTimeout(()=>{
          this.$refs.carousel.handleMouseEnter = this.edit

          this.actIndex = 0
      },100)
    },
    methods:{
        delHandleMouseEnter(){
            this.$refs.carousel.handleMouseEnter = this.edit
        },
        edit(){
          console.log('edit')
        },
        imgChange(index){
            this.actIndex = index
        }
    }
}
</script>

<style lang="less" scoped>
.swiper-bg {
  /*height: 710px;*/
  position: relative;
height: 100vh;
  .content {
    width: 100%;
    position: absolute;
    z-index: 999;

  }

  .swiper {

    img {
      width: 100%;
      height: 100vh;
    }
  }
}
/deep/.el-carousel__indicators--horizontal{
  bottom: 140px!important;
}
/deep/ .el-carousel__indicator--horizontal .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #B3B3B3;
  /*border: 1px solid #ffffff;*/
  border-radius: 50%;
  opacity: 0.5;
}
/deep/ .el-carousel__indicator--horizontal.is-active .el-carousel__button {
  width: 10px;
  height: 10px;
  background: #06070A;
  border-radius: 50%;
  opacity: .6;
}
  .list{
    width: 100%;
    height: 110px;
    /*position: absolute;*/
    /*bottom: 0;*/
    z-index: 999;
    position: fixed;
    bottom: 0;
    left: 0;
  }
  .actImg{
    transform:scale(1) !important;
  }
  .opctiy1{
    opacity: 1!important;
  }
</style>
