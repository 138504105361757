<template>
  <div class="box">
    <div class="box2">
      <el-menu style="padding-left: 10px;padding-top: .5rem" :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-menu-item index="1">
          <a href="/"><span>首页</span></a>
        </el-menu-item>
        <el-menu-item index="2">
          <a href="https://xiajin-mall.jwzhilian.com" target="_blank"><span>功能性纱线产业电商平台</span></a>
        </el-menu-item>
        <el-submenu index="3">
          <template slot="title"><span>典型应用场景</span></template>
          <div class="xialadakuang">
            <div class="xialakuang1">
              <el-menu-item index="2-1">
                <h5>数字营销应用场景</h5>
              </el-menu-item>
              <el-menu-item index="2-2"><a href="https://xiajin-mall.jwzhilian.com/company/" target="_blank">企业中心</a></el-menu-item>
              <el-menu-item index="2-3"><a href="https://xiajin-mall.jwzhilian.com/mall/" target="_blank">产品中心</a></el-menu-item>
              <el-menu-item index="2-4"><a href="https://xiajin-mall.jwzhilian.com/buy/" target="_blank">订单中心</a></el-menu-item>
              <el-menu-item index="2-5"><a href="https://xiajin-mall.jwzhilian.com/club/" target="_blank">链条中心</a></el-menu-item>
              <el-menu-item index="2-6"><a href="https://xiajin-mall.jwzhilian.com/job/" target="_blank">用工中心</a></el-menu-item>
              <el-menu-item index="2-7"><a href="https://xiajin-mall.jwzhilian.com/sell/" target="_blank">行业中心</a></el-menu-item>
              <el-menu-item index="2-8"><a href="https://xiajin-mall.jwzhilian.com/exhibit/" target="_blank">纺织博览会</a></el-menu-item>
            </div>
            <div class="xialakuang">
              <el-menu-item index="2-1">
                <h5>供应链金融应用场景</h5>
              </el-menu-item>
              <el-menu-item index="2-2"><a href="http://jinrong.jwzhilian.com/index/Supermarket/index" target="_blank">金融超市</a></el-menu-item>
              <el-menu-item index="2-3"><a href="http://jinrong.jwzhilian.com/index/Organization/index" target="_blank">金融机构</a></el-menu-item>
              <el-menu-item index="2-4"><a href="http://jinrong.jwzhilian.com/index/Jinrongxinyong/index" target="_blank">信用机构</a></el-menu-item>
              <el-menu-item index="2-5"><a href="http://jinrong.jwzhilian.com/index/news_list/index" target="_blank">资讯动态</a></el-menu-item>
              <el-menu-item index="2-6"><a href="http://jinrong.jwzhilian.com/index/Gcxyd/index" target="_blank">特色金融服务专区</a></el-menu-item>
            </div>
            <!-- <div class="xialakuang">
              <el-menu-item index="2-1">
                <h5>协同制造应用场景</h5>
              </el-menu-item>
              <el-menu-item index="2-2">供应链协同平台</el-menu-item>
              <el-menu-item index="2-3">资源共享平台</el-menu-item>
              <el-menu-item index="2-4">产能协同平台</el-menu-item>
              <el-menu-item index="2-5">工厂实时监控</el-menu-item>
              <el-menu-item index="2-6">质检系统</el-menu-item>
              <el-menu-item index="2-7">园区调度系统</el-menu-item>
              <el-menu-item index="2-8">数据系统</el-menu-item>
              <el-menu-item index="2-9">智能决策系统</el-menu-item>
            </div> -->
          </div>
        </el-submenu>
        <el-menu-item index="4" class="index2">
          <template><span><a target="_blank" href="https://big-screen.jwzhilian.com/#/index">数据看板</a></span></template>
        </el-menu-item>
        <el-menu-item index="5">
          <template><span><a target="_blank" href="https://xiajin-mall.jwzhilian.com/news/">行业资讯</a></span></template>
        </el-menu-item>
        <el-menu-item index="6">
          <template><span><a target="_blank" href="https://xiajin-mall.jwzhilian.com/quote/">行业指数</a></span></template>
        </el-menu-item>
        <el-menu-item index="7">
          <template><span>
            <a target="_blank" href="http://shengtai.jwzhilian.com">生态协同</a>
            <!-- <router-link :to="{path:'/Build'}">生态协同</router-link> -->
          </span></template>
        </el-menu-item>
<!--        <el-menu-item index="8">-->
<!--          <template><span><a href="#">帮助中心</a></span></template>-->
<!--        </el-menu-item>-->
      </el-menu>
    </div>

  </div>
</template>
<script>
export default {
  data () {
    return {
      activeIndex: '1',
      activeIndex2: '1'
    }
  },
  methods: {
    handleSelect (key, keyPath) {
      console.log(key, keyPath)
    }
  }
}
</script>
<style lang="less" scoped>
.box {
  width: 100%;
  height: 50px;
  position: relative;

  // .imgbox {
  //     width: 150px;
  //     float: left;
  // }

  .box2 {
    height: 50px;
    // width: 1300px;
    width: 100%;
    overflow: hidden;
    /*border-top: 1px solid #777272;*/
    /*border-bottom: 1px solid #777272;*/
    /*border-top: 1px solid rgba(255, 255, 255, 0.15);*/
    /*border-bottom: 1px solid rgba(255, 255, 255, 0.15);*/

    .el-menu {
      background-color: transparent;
      font-weight: bold;

      .el-menu-item:hover {
        background-color: transparent;
        color: dodgerblue;
      }

    }

    .el-menu-item {
      color: #EFF7F8;
      font-size: 17px;
      text-decoration: none;
    }

    span {
      color: #fff;
      font-size: 17px;
    }

  }

}
.el-menu--horizontal>.el-menu-item.is-active{
  border-bottom: 0!important;
}
.el-menu--horizontal>.el-menu-item{
  height: 50px!important;
  line-height: 40px!important;
}
.xialadakuang {
  // width: 1680px;
  widows: 100;
  height: 100%;
  // background-color: #ccc;
  padding: 30px;

  .xialakuang1 {
    float: left;
    padding-right: 50px;
  }

  .xialakuang {
    padding-right: 50px;
    float: left;
    margin-left: 0px;

  }

  .el-menu-item {
    color: #333;
  }

  .el-menu-item h5 {
    color: dodgerblue;
    font-size: 14px;
    font-weight: bold;
  }
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background-color: transparent !important;
}

::v-deep .el-menu--horizontal > .el-submenu .el-submenu__title span:hover {
  color: dodgerblue;
}

a {
  text-decoration: none;
  color: black;
}
/deep/.el-submenu__title{
  height: 50px!important;
  line-height: 50px!important;
  margin-top: -3.5px;
}
</style>
