import Vue from 'vue'
import VueRouter from 'vue-router'

// // const router = new VueRouter({
// //     routes,
// //   })

// //   export default router

// import Vue from 'vue'
// import VueRouter from 'vue-router'
// Vue.use(VueRouter)
// 导入路由组件
import Home from '@/views/Home'
import detail from '@/views/detail'
import Register from '@/views/Register'
import Login from '@/views/Login'
import Build from '@/views/Build'

Vue.use(VueRouter)

// 配置路由规则
const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/Home',
    component: Home
  },
  {
    path: '/Build',
    component: Build
  },
  {
    // 注册路由
    path: '/Register',
    component: Register
  },
  {
    // 登录路由
    path: '/Login',
    component: Login
  },
  {
    path: '/detail',
    component: detail
  }
]

const router = new VueRouter({
  routes
})

export default router
