import Axios from 'axios'
import Route from '../router/index'

const production = 'https://xiajin.jwzhilian.com'// 生产
const development = ''// 本地

Axios.defaults.baseURL = process.env.NODE_ENV === 'development' ? development : production

Axios.interceptors.request.use(
  config => {
    return config
  }
)
Axios.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(new Error(error))
  }
)

export default Axios
