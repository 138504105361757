<template>
  <div>
    <div class="footer">
      <div class="footer-container">
        <div class="footerList">
          <div class="footerItem">
            <ul class="footerItemCon" >
              <img src="../../assets/imgs/logo.png" style="width:155px;margin: 20px 0" alt="">
<!--              <li class="phone">0534-5040001</li>-->
<!--              <li>服务邮箱：jwzhilian@126.com</li>-->
              <li style="color: white">咨询热线：0534-5040001</li>
              <li style="color: white">服务邮箱：jwzhilian@126.com</li>
              <li style="color: white">服务时间：工作日09:00-17:30</li>
              <div style="width: 100%;display: flex;justify-content: space-between;margin-top: 20px">
                <div style="width: 100px">
                  <img src="./images/erweima.png" alt="" style="width: 100px;height: 100px">
                  <div style="text-align: center;width: 100px;font-size: 12px;line-height: 25px">经纬智联公众号</div>
                </div>
                <!-- <div style="width: 100px">
                  <img src="./images/erweima.png" alt="" style="width: 100px;height: 100px">
                  <div style="text-align: center;width: 100px;font-size: 14px;line-height: 25px">微信客服</div>
                </div> -->
              </div>

            </ul>
          </div>
          <div class="footerItem">
            <ul class="footerItemCon">
              <h4>友情链接</h4>
              <li><a href="http://www.sdfx.cn/" target="_blank">山东省纺织服装行业协会</a></li>
              <li><a href="">山东凌锐信息科技有限公司</a></li>
              <li><a href="http://www.yjkjgs.com" target="_blank">远景云平台</a></li>
<!--              <li>纺织信息网</li>-->
<!--              <li>政企直通车</li>-->
<!--              <li>金融云平台</li>-->
<!--              <li>政企直通车</li>-->
<!--              <li>金融云平台</li>-->
            </ul>
          </div>
          <div class="footerItem">

            <ul class="footerItemCon">
              <h4>快速入口</h4>
              <li><a href="https://xiajin-mall.jwzhilian.com/buy/" target="_blank">供需中心</a></li>
              <li><a href="https://xiajin-mall.jwzhilian.com/sell/" target="_blank">平台产品</a></li>
              <li><a href="https://xiajin-mall.jwzhilian.com/quote/" target="_blank">市场行情</a></li>
              <li><a href="https://xiajin-mall.jwzhilian.com/news/" target="_blank">行业资讯</a></li>
            </ul>
          </div>
          <div class="footerItem">

            <ul class="footerItemCon">
              <h4>子站连接</h4>
              <li><a href="http://jinrong.jwzhilian.com" target="_blank">金融服务云平台</a></li>
              <li><a href="https://xiajin-mall.jwzhilian.com/job/" target="_blank">人才赋能云平台</a></li>
              <li><a href="http://keji.jwzhilian.com" target="_blank">科技创新云平台</a></li>
              <li>
                <!-- <a href="#" target="_blank">生态服务云平台</a> -->
                <router-link :to="{path:'/Build'}">生态服务云平台</router-link>
              </li>
              <li>
                <!-- <a href="#" target="_blank">服装行业产业大脑</a> -->
                <router-link :to="{path:'/Build'}">服装行业产业大脑</router-link>
              </li>
            </ul>
          </div>
          <div class="footerItem">
            <ul class="footerItemCon">
              <h4>解决方案</h4>
              <li>
                <!-- <a href="#" target="_blank">纺织行业智能制造解决方案</a> -->
                <router-link :to="{path:'/Build'}">纺织行业智能制造解决方案</router-link>
              </li>
              <li>
                <!-- <a href="#" target="_blank">印染行业智能制造解决方案</a> -->
                <router-link :to="{path:'/Build'}">印染行业智能制造解决方案</router-link>
              </li>
              <li>
                <!-- <a href="#" target="_blank">服装行业智能制造解决方案</a> -->
                <router-link :to="{path:'/Build'}">服装行业智能制造解决方案</router-link>
              </li>
              <li>
                <!-- <a href="#" target="_blank">产业用纺织品解决方案</a> -->
                <router-link :to="{path:'/Build'}">产业用纺织品解决方案</router-link>
              </li>
              <li>
                <!-- <a href="#" target="_blank">面料贸易解决方案</a> -->
                <router-link :to="{path:'/Build'}">面料贸易解决方案</router-link>
              </li>
            </ul>
          </div>
        </div>

        <div class="copyright">
          <p>版权所有 ©经纬智联（山东）数字科技有限公司 备案/许可证号:鲁ICP备2023017400号-1</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style lang="less" scoped>
.footer {


  color: #C9C9C9;
  background: #110F16;

  .footer-container {
    background: url(../../assets/imgs/foot.jpg);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    width: 100%;
    margin: auto;
    overflow-x: hidden;

    .footerList {
      box-sizing: border-box;
      padding: 0 16%;
      /*border-top: 1px solid #e4e1e1;*/
      overflow: hidden;
      display: flex;
      justify-content: space-between;
      .footerItem {
        // width: 16.6666667%;
        float: left;
        /*padding-left: 70px;*/

        h4 {
          padding-top: 20px;
          height: 50px;
          font-size: 18px;
          color: white;
          width: 80px;
          border-bottom: 1px solid #FFF;

        }

        .footerItemCon {
          .phone {
            font-size: 27px;
          }

          li {
            margin-top: 8px;
            list-style-type: none;
            font-size: 14px;
            line-height: 25px;

          }
        }

        &:last-child img {
          width: 121px;
        }
      }
    }

    .copyright {
      border: 0!important;
      padding-top: 10px;
      p {
        border: 0!important;
        color: #767685;

        // background-color: pink;
        text-align: center;
        font-size: 14px;
      }

      .helpLink {
        text-align: center;

        li {
          display: inline;

          .space {
            border-left: 1px solid #666;
            width: 1px;
            height: 13px;
            background: #666;
            margin: 8px 10px;
          }
        }
      }

      p {
        margin: 10px 0;

      }
    }
  }
}

a {
  text-decoration: none;
  font-weight: bold;
  color: #7E99EA!important;

}
  a:hover{
    text-decoration: underline;
  }
</style>
