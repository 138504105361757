<template>
  <div id="app" class="scrollDiv" ref="scrollDiv"  @scroll="handleScroll">
    <router-view></router-view>
    <el-backtop target=".scrollDiv" :visibility-height="1000" :right="40" :bottom="140" style="z-index: 9"></el-backtop>
  </div>
</template>

<script>
import Header from '@/components/Header/index.vue'
import Home from '@/views/Home/index.vue'
import Footer from '@/components/Footer/index.vue'
export default {
  name: 'App',
  mounted () {
    this.$nextTick(() => {
      this.$scrollDiv.value = this.$refs.scrollDiv
    })
  },
  methods: {
      handleScroll(e) {
          // 处理滚动条变化事件
          this.$scrollTop.value = this.$refs.scrollDiv.scrollTop;
          // console.log('this.$scrollTop',this.$scrollTop)
      }
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Header,
    // eslint-disable-next-line vue/no-unused-components
    Home,
    Footer
  }
}
</script>

<style lang="less" scoped>
  #app{
    overflow-y: auto;
    overflow-x: hidden;
    width: 100%;
    height: 100vh;
  }
</style>
