<template>
    <div class="box">

        <div class="title">
          <div class="news">LATEST<br/>NEWS</div>
           <h2>行业资讯</h2>
            <p>热点/痛点/观点连点成线，<br/>
                物联大事件脉络尽在掌握
            </p>
            <div class="list">
              <div @click="tab(0)" :class="actIndex == 0?'act':''">行业资讯</div>
              <div @click="tab(1)" :class="actIndex == 1?'act':''">宏观动态</div>
              <div @click="tab(2)" :class="actIndex == 2?'act':''">商品分析</div>
             </div>
          </div>
          <div class="body">
            <div @click="tiaozhuan(item.itemid)" class="bodyItem" v-for="item in listData[actIndex]">
              <div class="dian"><div></div></div>
              <div class="date">
                <div class="month" >{{item.month}}</div>
                <div class="year" style="font-size: 18px">{{item.year}}</div>
              </div>
              <div class="content">
                <div class="month" style="font-size: 20px;font-weight: normal;">{{item.title}}</div>
                <div class="year" style="margin-top: 10px;">{{item.content}}</div>
              </div>
            </div>
            <div class="detail" @click="goMore">进一步了解 →</div>
          </div>
<!--        <div class="news">-->
<!--            <div :class="isShow?'newspage item_1 show':'newspage item_1'">-->
<!--                <img src="./images/行业资讯1.png" alt="">-->
<!--                <div class="newspageTitle">行业资讯</div>-->
<!--                <p>2023年第一批高新区先进纺织服装企业政策扶助资金申报</p>-->
<!--              <li @click="jumpPage(1)"><span class="li">纺织服装企国家新一代自主安全计算系统产业集群...</span><span class="li2">03-06</span></li>-->
<!--              <li @click="jumpPage(2)"><span class="li">纺织服装企国家新一代自主安全计算系统产业集群...</span><span class="li2">03-06</span></li>-->
<!--            </div>-->
<!--            <div :class="isShow?'newspage item_2 show':'newspage item_2'">-->
<!--                <img src="./images/行业资讯2.png" alt="">-->
<!--              <div class="newspageTitle">政策速递</div>-->

<!--              <p>2023年第一批高新区先进纺织服装企业政策扶助资金申报</p>-->
<!--              <li @click="jumpPage(3)"><span class="li">纺织服装企国家新一代自主安全计算系统产业集群...</span><span class="li2">03-06</span></li>-->
<!--              <li @click="jumpPage(4)"><span class="li">纺织服装企国家新一代自主安全计算系统产业集群...</span><span class="li2">03-06</span></li>-->
<!--            </div>-->
<!--            <div :class="isShow?'newspage item_3 show':'newspage item_3'">-->
<!--                <img src="./images/行业资讯3).png" alt="">-->
<!--              <div class="newspageTitle">知识库</div>-->

<!--              <p>2023年第一批高新区先进纺织服装企业政策扶助资金申报</p>-->
<!--              <li @click="jumpPage(5)"><span class="li">纺织服装企国家新一代自主安全计算系统产业集群...</span><span class="li2">03-06</span></li>-->
<!--              <li @click="jumpPage(6)"><span class="li">纺织服装企国家新一代自主安全计算系统产业集群...</span><span class="li2">03-06</span></li>-->
<!--            </div>-->
<!--        </div>-->
    </div>
</template>

<script>
export default {
  name: 'News',
    data () {
        return {
            listData:[
                // {
                //     month:'07 · 10',
                //     year:'2023',
                //     title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
                //     content:'纺织服装企国家新一代自主安全计算系统产业集群...'
                // },
                // {
                //     month:'07 · 10',
                //     year:'2023',
                //     title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
                //     content:'纺织服装企国家新一代自主安全计算系统产业集群...'
                // },
                // {
                //     month:'07 · 10',
                //     year:'2023',
                //     title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
                //     content:'纺织服装企国家新一代自主安全计算系统产业集群...'
                // },
                // {
                //     month:'07 · 10',
                //     year:'2023',
                //     title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
                //     content:'纺织服装企国家新一代自主安全计算系统产业集群...'
                // },
            ],
            top:this.$scrollTop,
            isShow:false,
            actIndex:0,
        }
    },
    watch:{
        top:{
            handler(newV,oldV) {
                console.log('top',newV.value)
                let top = newV.value
                if(top>1870){
                    // let val = 500-300
                    this.isShow = true
                }
            },
            deep: true
        },
    },
    created(){
    //cbb_auth
      this.init()
  },
  methods:{
    tiaozhuan(itemid){
      // alert(itemid)
      // location.href = 'https://xiajin-mall.jwzhilian.com/news/show.php?itemid='+itemid
      window.open('https://xiajin-mall.jwzhilian.com/news/show.php?itemid='+itemid)

      //https://xiajin-mall.jwzhilian.com/news/show.php?itemid=
    },
    goMore() {
      window.open('https://xiajin-mall.jwzhilian.com/news/')
    },
    init(){
      //https://xiajin-mall.jwzhilian.com/news/list.php?type=dhl
      var that =this
      this.$axios.post("/news/list.php",{
        type: 'dhl'
      },{
        headers:{
          'Content-Type':'application/x-www-form-urlencoded',
          'Accept':'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
        }
      }).then(res => {
        //请求成功，触发then中的函数
        if(res.data.code == 1 ){
          that.listData =res. data.data

        }
      })
      .catch(error =>
        //请求失败，触发catch中的函数 可省略
        console.log(error)
      )

    },

      tab(num){
          this.actIndex = num
          // if(num == 0){
          //     this.listData = [
          //         {
          //             month:'07 · 10',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //         {
          //             month:'07 · 10',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //         {
          //             month:'07 · 10',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //         {
          //             month:'07 · 10',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //     ]
          // }
          // if(num == 1){
          //     this.listData = [
          //         {
          //             month:'07 · 11',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //         {
          //             month:'07 · 11',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //         {
          //             month:'07 · 12',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //         {
          //             month:'07 · 12',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //     ]
          // }
          // if(num == 2){
          //     this.listData = [
          //         {
          //             month:'07 · 12',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //         {
          //             month:'07 · 12',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //         {
          //             month:'07 · 13',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //         {
          //             month:'07 · 14',
          //             year:'2023',
          //             title:'2023年第一批高新区先进纺织服装企业政策扶助资金申报',
          //             content:'纺织服装企国家新一代自主安全计算系统产业集群...'
          //         },
          //     ]
          // }

      },
      jumpPage(flag){
          let url = ''
          if(flag == 1){
              //要跳转的地址
              url = 'http://www.baidu.com'
          }
          window.open(url)
      }
  }
}
</script>

<style lang="less" scoped>
.box{
  width: 100%;
  height: 870px;
  position: relative;
  background: url(../../../assets/imgs/hangyezixun.jpg);
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.title {
  position: absolute;
  top: 8%;
  left: 20%;
    h2 {
      padding-top: 35px;
        height: 41px;
      line-height: 41px;
        color: rgba(51, 51, 51, 1);
        font-size: 28px;
    }

    p {
        margin-top: 45px;
        line-height: 28px;
        color: rgba(136, 136, 136, 1);
        font-size: 16px;
    }
}
.list{
  margin-top: 60px;
  font-size: 20px;
  color: rgba(51, 51, 51, 1);
  line-height: 40px;
  cursor: pointer;
  div{
    margin-top: 20px;
    width: 83px;

  }
}
.list>div:hover,.act{
  color:#1D418B;
  border-bottom: 3px solid #1D418B;
}
.news {
     color: #E7E7E7;
      font-size: 50px;
  font-weight: bold;
}
  .body{
    position: absolute;
    top: 11%;
    right: 0%;
    width: 58%;
    height: 100px;
    .bodyItem{
      height: 159px;
      cursor: pointer;
      padding: 20px 5px;
      width: 66%;
      border-bottom: 1px solid #AFAFAF;
      display: flex;
      align-items: start;
      font-weight: bold;
      .dian{
        div{
          border-radius: 50%;
          width: 7px;
          height: 7px;
          background: #223B8E;
          margin-top: 20px;
          margin-right: 30px;
        }
      }
      .month{
        color: rgba(51, 51, 51, 1);
        font-size: 28px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
      .year{
        color: #949494;
        font-size: 16px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* 设置最大显示行数 */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
      }
      .content{
        padding-left: 40px;
        width: 430px
      }

    }
    .bodyItem:hover{
      .content{
        text-decoration: underline;
      }
    }
    .detail{
      color: #949494;
      font-size: 20px;
      margin-top: 50px;
      cursor: pointer;
    }
  }

</style>
