<template>
    <div class="box" style="position: relative">
            <div class="title" id="title">
                <h2>聚集功能性纱线领域共性解决方案</h2>
              <div style="position: absolute;top: 50px;left: calc(50% - 30px);">
                <div style="width: 60px;background: #193D84;height: 3px;margin:0 auto"></div>
              </div>
                <p style="padding-top: 35px">集成纱线产业创新应用，解决企业数字化转型水平低、行业知识难以获取等问题</p>
            </div>
          <div style="height: 65vh;margin-top: 60px;position: relative">
            <img src="../../../assets/imgs/fangan/back.png" style="width: 100%;height: 65vh;position: relative;" alt="">
            <div style="position: absolute;width: 60%;left: 20%;top: 0;height: 65vh">
              <el-row :gutter="20">
                <el-col :span="5" style="color: #ffffff;height: 65vh;padding: 12vh 0 14vh">
                  <div style="font-size: 25px;font-weight: bold;margin-bottom: 20px">纺纱行业智能制造</div>
                  <div style="margin-bottom: 5px">提高生产车间管理制度执行效率</div>
                  <div style="margin-bottom: 20px">为车间现场管理建立规范的知识库体系</div>
                  <el-button style="background: rgba(3,43,132,0.26);border: none;color: #ffffff" @click="toDetail">查看详情</el-button>
                </el-col>
                <el-col :span="8" :offset="2" style="color: #000000;display: flex;flex-direction: column;justify-content: space-between;height: 65vh;padding: 12vh 0 14vh">
                  <div class="block" @click="dialogVisible = true">
                    <div style="font-size: 20px;font-weight: bold;margin-bottom: 5px">纺纱物联系统</div>
                    <div style="color: #888888;font-size: 15px;">随时随地下达、接收生产命令，通过统一服务平台，迅速实现调度、接单、派单。</div>
                  </div>
                  <div class="block" @click="dialogVisible = true">
                    <div style="font-size: 20px;font-weight: bold;margin-bottom: 5px">纺纱物联系统</div>
                    <div style="color: #888888;font-size: 15px;">随时随地下达、接收生产命令，通过统一服务平台，迅速实现调度、接单、派单。</div>
                  </div>
                  <div class="block" @click="dialogVisible = true">
                    <div style="font-size: 20px;font-weight: bold;margin-bottom: 5px">纺纱物联系统</div>
                    <div style="color: #888888;font-size: 15px">随时随地下达、接收生产命令，通过统一服务平台，迅速实现调度、接单、派单。</div>
                  </div>
                </el-col>
                <el-col :span="8" :offset="1" style="color: #000000;display: flex;flex-direction: column;justify-content: space-between;height: 65vh;padding: 12vh 0 14vh">
                  <div class="block" @click="dialogVisible = true">
                    <div style="font-size: 20px;font-weight: bold;margin-bottom: 5px">纺纱物联系统</div>
                    <div style="color: #888888;font-size: 15px;">随时随地下达、接收生产命令，通过统一服务平台，迅速实现调度、接单、派单。</div>
                  </div>
                  <div class="block" @click="dialogVisible = true">
                    <div style="font-size: 20px;font-weight: bold;margin-bottom: 5px">纺纱物联系统</div>
                    <div style="color: #888888;font-size: 15px;">随时随地下达、接收生产命令，通过统一服务平台，迅速实现调度、接单、派单。</div>
                  </div>
                  <div class="block" @click="dialogVisible = true">
                    <div style="font-size: 20px;font-weight: bold;margin-bottom: 5px">纺纱物联系统</div>
                    <div style="color: #888888;font-size: 15px">随时随地下达、接收生产命令，通过统一服务平台，迅速实现调度、接单、派单。</div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>

      <el-dialog
        title="联系我们"
        :visible.sync="dialogVisible"
      width="25%">
        <div class="footerItem" style="border-radius: 20px;padding: 20px 20px 3px">
          <ul class="footerItemCon" style="backdrop-filter: blur(3px);border-radius: 20px;padding-bottom: 10px;background: #fffdfd1a">
            <img src="../../../assets/imgs/logo.png" style="width:155px;margin: 20px 0" alt="">
            <!--              <li class="phone">0534-5040001</li>-->
            <!--              <li>服务邮箱：jwzhilian@126.com</li>-->
            <li style="color: white">咨询热线：0534-5040001</li>
            <li style="color: white">服务邮箱：<span @click="openMail" style="color: #4C97F3;cursor: pointer">jwzhilian@126.com</span></li>
            <li style="color: white">服务时间：工作日09:00-17:30</li>
            <div style="display: flex;justify-content: space-between;margin-top: 20px">
              <div style="width: 100px">
                <img src="../../../components/Footer/images/erweima.png" alt="" style="width: 100px;height: 100px">
                <div style="text-align: center;width: 100px;font-size: 12px;line-height: 25px;color: #ffffff">经纬智联公众号</div>
              </div>
              <!-- <div style="width: 100px">
                <img src="./images/erweima.png" alt="" style="width: 100px;height: 100px">
                <div style="text-align: center;width: 100px;font-size: 14px;line-height: 25px">微信客服</div>
              </div> -->
            </div>

          </ul>
        </div>
      </el-dialog>
    </div>
</template>

<script>
export default {
  name: 'Solution',
  data () {
    return {
      activeName: '1',
        top:this.$scrollTop,
        isShow:true,
      dialogVisible: false
    }
  },
    watch:{
        top:{
            handler(newV,oldV) {
                console.log('top',newV.value)
                let top = newV.value
                if(top>1100){
                   // let val = 500-300
                    this.isShow = true
                }
            },
            deep: true
        },
    },
    mounted() {
        window.addEventListener('scroll', function() {
            var scrollPosition = window.scrollY;
            console.log('scrollPosition',scrollPosition)
            // 根据滚动条位置来判断是否需要添加或删除CSS类
            if (scrollPosition > 500) {
                document.getElementById('title').classList.add('animate');
            } else {
                document.getElementById('title').classList.remove('animate');
            }
        });
    },
    methods:{
      toDetail(){
          let url = window.location.href
          window.open(url+'detail')
          // this.$router.push({path:'/detail', query: {param: param}})
      },
      openMail() {
        window.open('https://jwzhilian@126.com')
      }
    }
}
</script>

<style lang="less" scoped>
.footerItem {
  // width: 16.6666667%;
  //float: left;
  /*padding-left: 70px;*/
  background: url(../../../assets/imgs/foot.jpg);
  h4 {
    padding-top: 20px;
    height: 50px;
    font-size: 18px;
    color: white;
    width: 80px;
    border-bottom: 1px solid #FFF;

  }

  .footerItemCon {
    .phone {
      font-size: 27px;
    }

    li {
      margin-top: 8px;
      list-style-type: none;
      font-size: 14px;
      line-height: 25px;

    }
  }

  &:last-child img {
    width: 121px;
  }
}
.title {
  h2 {
    margin: auto;
    top: 658px;
    width: 1200px;
    height: 41px;
    line-height: 41px;
    color: rgba(51, 51, 51, 1);
    font-size: 28px;
    text-align: center;
    /*padding-top: 15px;*/
    color: rgba(51, 51, 51, 1);
    font-size: 28px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
  }

  p {
    text-align: center;
    height: 35px;
    line-height: 35px;
    color: rgba(136, 136, 136, 1);
    padding-top: 9px;
    color: rgba(136, 136, 136, 1);
    font-size: 18px;
    text-align: center;
    font-family: SourceHanSansSC-regular;
  }
}
.block{
  padding: 0.4vh;
}
.block:hover{
  cursor: pointer;
  background: #EDF2FF;
  color: #1f58c3;
  border-radius: 10px;
}
</style>
