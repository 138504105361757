<template>
    <div></div>
</template>
<script>

export default {
// eslint-disable-next-line vue/multi-word-component-names
  name: 'Build'
}
</script>

<style scoped>
  div{
    width:100%;
    height: 100%;
    background: url("./images/11.jpg") no-repeat center center;
      background-size: 100% 100%;
  }
</style>
