<template>
    <div class="box">

        <p>山东省纺织服装产业链数字经济总部</p>
        <p class="Subtitle">重点加强公共服务属性，围绕产业生态、新智造、共性技术三大方向，建设全省的纺织服装产业互联网平台</p>
        <div class="boximg"><img src="./images/产业中间图.png" alt=""></div>
        <div class="Lsit">
            <div class="LsitOne">
                <h5>纺织服装产业云平台</h5>
                <span>建立政府侧、产业侧数据仓，推动公共资源数据的共享应用</span>
            </div>
            <div class="LsitTow">
                <h5>纺织服装产业金融云平台</h5>
                <span>为企业提供链普惠服务，解决企业资金周转，资金回笼问题</span>
            </div>
            <div class="LsitThree">
                <h5>纺织服装产业科技创新平台</h5>
                <span>数字经济总部产教融合、“产、学、研、创”共享技术技能创新平台</span>
            </div>
            <div class="LsitFour right">
                <h5>纺织服装产业大脑</h5>
                <span>建设一批“未来工厂”为引领的“数字化车间”“智能工厂”等新制作企业群</span>
            </div>
            <div class="LsitFive right">
                <h5>纺织服装产业生态服务平台</h5>
                <span>建设“平台+应用+生态+数据”的纺织服装产业生态服务平台</span>
            </div>
            <div class="LsitSix right">
                <h5>纺织服装产业人才赋能平台</h5>
                <span>建立学院资源库和企业需求库将企业人才需求和培训机构提供的人才精准匹配</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Largeimg'
}
</script>

<style lang="less" scoped>
.box {
    width: 100%;
    height: 800px;
    position: relative;

    p {
        margin: auto;
        top: 658px;
        width: 1200px;
        height: 41px;
        color: rgba(51, 51, 51, 1);
        font-size: 28px;
        text-align: center;
    }

    .Subtitle {
        text-align: center;
        margin-top: 10px;
        width: 1200px;
        height: 35px;
        color: rgba(136, 136, 136, 1);
        font-size: 16px;
    }

    .boximg {
        text-align: center;
        overflow: hidden;
        margin-top: 30px;
    }

    .Lsit {
        width: 100%;

        div {
            width: 300px;
            text-align: right;

            h5 {
                font-size: 20px;
                font-weight: 500;
                color: dodgerblue;
                display: block;
                width: 300px;
            }

            span {
                width: 240px;
            }
        }

        .right {
            width: 300px;
            text-align: left;

            h5 {
                font-size: 20px;
                font-weight: 500;
                color: dodgerblue;
                display: block;
                width: 300px;
            }

            span {
                width: 240px;
            }
        }

        .LsitOne {
            position: absolute;
            top: 150px;
            left: 220px;
        }

        .LsitTow {
            position: absolute;
            top: 300px;
            left: 220px;
        }

        .LsitThree {
            position: absolute;
            top: 450px;
            left: 220px;
        }

        .LsitFour {
            position: absolute;
            top: 150px;
            right: 220px;
        }

        .LsitFive {
            position: absolute;
            top: 300px;
            right: 220px;
        }

        .LsitSix {
            position: absolute;
            top: 450px;
            right: 220px;
        }
    }
}</style>
