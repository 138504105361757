<template>
  <div>
    <div class="login">
      <!-- 登录大盒子 -->
      <div class="section" style="flex:1;position: relative;margin-left: 5%">
        <img src="../../assets/imgs/登陆页/圆.png"  class="yuan" alt="">
        <img src="../../assets/imgs/登陆页/圆线.png"  class="line"  alt="">
        <img src="../../assets/imgs/登陆页/线.png" class="lines" style="position:absolute;top:23%; left:5%; width:90%;" alt="">
        <img class="ico a" src="../../assets/imgs/登陆页/1云平台-(1).png" style="position:absolute;top:25%; left:15%;" alt="">
        <img class="ico b" src="../../assets/imgs/登陆页/3服装.png" style="position:absolute;top:22%; left:45%;" alt="">
        <img class="ico c" src="../../assets/imgs/登陆页/5生态服务.png" style="position:absolute;top:26%; left:80%;" alt="">
        <img class="ico d" src="../../assets/imgs/登陆页/6大脑.png" style="position:absolute;top:53%; left:85%;" alt="">
        <img class="ico e" src="../../assets/imgs/登陆页/4人才.png" style="position:absolute;top:63%; left:55%;" alt="">
        <img class="ico f" src="../../assets/imgs/登陆页/2金融交易.png" style="position:absolute;top:52%; left:8%;" alt="">
      </div>
      <div style="flex:1">
        <div class="loginform">
          <!-- 登录标题 -->
          <div class="logintitle">欢迎使用纺织服装产业云平台</div>
          <div class="userbox">
            <el-tabs :stretch="true" v-model="activeName" @tab-click="handleClick">
              <el-tab-pane label="个人登录" name="first">
                <div style="margin-top:30px;display: flex;margin-bottom: 20px;border-bottom: 1px solid #fff;padding-left: 20px;align-items: center">
              <i class="el-icon-user el-icon"></i>
              <el-input style="height: 60px;" v-model="input" placeholder="请输入账号/手机" @keyup.native="$event.target.value = $event.target.value.replace(/[^\w./]/ig,'')"></el-input>
            </div>
            <div class="password" style="display: flex;margin-bottom: 20px;border-bottom: 1px solid #fff;padding-left: 20px;align-items: center">
              <i class="el-icon-lock el-icon"></i>
              <el-input style="height: 60px;" v-model="password" placeholder="请输入登录密码" show-password @keyup.native="$event.target.value = $event.target.value.replace(/[^\w./]/ig,'')"></el-input>
            </div>
            <button style="background: #4C97F3" @click="register">注册</button>
            <div class="zhanghao">
              <p class="zhuce">已有账号？<router-link to="/Login"  >立即登录</router-link>
              </p>
            </div>

              </el-tab-pane>
              <el-tab-pane label="企业用户" name="second">

            <div style="margin-top:30px;display: flex;margin-bottom: 20px;border-bottom: 1px solid #fff;padding-left: 20px;align-items: center">
              <i class="el-icon-office-building el-icon"></i>
              <el-input style="height: 60px;" v-model="company" placeholder="请输入企业名称"></el-input>
            </div>

                <div style="display: flex;margin-bottom: 20px;border-bottom: 1px solid #fff;padding-left: 20px;align-items: center">
                  <i class="el-icon-user el-icon"></i>
                  <el-input style="height: 60px;" v-model="input" placeholder="请输入账号/手机" @keyup.native="$event.target.value = $event.target.value.replace(/[^\w./]/ig,'')"></el-input>
                </div>

            <div class="password" style="display: flex;margin-bottom: 20px;border-bottom: 1px solid #fff;padding-left: 20px;align-items: center">
              <i class="el-icon-lock el-icon"></i>
              <el-input style="height: 60px;" v-model="password" placeholder="请输入登录密码" show-password @keyup.native="$event.target.value = $event.target.value.replace(/[^\w./]/ig,'')"></el-input>
            </div>
            <button style="background: #4C97F3" @click="register">注册</button>
            <div class="zhanghao">
              <p class="zhuce">已有账号？<router-link to="/Login"  >立即登录</router-link>
              </p>
            </div>




              </el-tab-pane>
              <el-tab-pane label="政府用户" name="third">


            <div style="margin-top:30px;display: flex;margin-bottom: 20px;border-bottom: 1px solid #fff;padding-left: 20px;align-items: center">
              <i class="el-icon-office-building el-icon"></i>
              <el-input style="height: 60px;" v-model="company" placeholder="请输入企业名称"></el-input>
            </div>

                <div style="display: flex;margin-bottom: 20px;border-bottom: 1px solid #fff;padding-left: 20px;align-items: center">
                  <i class="el-icon-user el-icon"></i>
                  <el-input style="height: 60px;" v-model="input" placeholder="请输入账号/手机" @keyup.native="$event.target.value = $event.target.value.replace(/[^\w./]/ig,'')"></el-input>
                </div>

            <div class="password" style="display: flex;margin-bottom: 20px;border-bottom: 1px solid #fff;padding-left: 20px;align-items: center">
              <i class="el-icon-lock el-icon"></i>
              <el-input style="height: 60px;" v-model="password" placeholder="请输入登录密码" show-password @keyup.native="$event.target.value = $event.target.value.replace(/[^\w./]/ig,'')"></el-input>
            </div>
            <button style="background: #4C97F3" @click="register">注册</button>
            <div class="zhanghao">
              <p class="zhuce">已有账号？<router-link to="/Login"  >立即登录</router-link>
              </p>
            </div>

              </el-tab-pane>
            </el-tabs>





          </div>
          <div class="account">
          </div>
        </div></div>
    </div>
  </div>
</template>

<script>
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: 'Login',
        data () {
            return {
                input: '',
                password: '',
                activeName: 'first',
                company:'',
      regid:5
            }
        },
        comments: {
        },
        methods: {
    handleClick(target,action){
      if(target.name== 'first'){
        this.regid = 5
      }
      if(target.name== 'second'){
        this.regid =  6
      }
      if(target.name== 'third'){
        this.regid =  8
      }
    },
    register:function(){
      this.company = this.company.replace(/\s*/g,'')
      this.input = this.input.replace(/\s*/g,'')
      this.password = this.password.replace(/\s*/g,'')

      if (this.regid === 6 || this.regid === 8) {
        if (!this.company) {
          this.$message({
            message: '请输入企业名称',
            type: 'warning'
          });
          return;
        }
      }

      if (!this.input) {
        this.$message({
          message: '请输入账号/手机',
          type: 'warning'
        });
        return;
      } else if (!this.password) {
        this.$message({
          message: '请输入登录密码',
          type: 'warning'
        });
        return;
      }

      var that =this
      this.$axios.post("/member/register.php",{
        forward: 'https://xiajin-mall.jwzhilian.com/',
        action:'registerdhl',
        'post[regid]': this.regid,
        'post[company]': this.company,
        'post[username]': this.input,
        'post[password]': this.password,
        'post[cpassword]':  this.password,
        'submit': '立即注册',
      },{
        headers:{
          'Content-Type':'application/x-www-form-urlencoded',
          'Accept':'text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.7',
        }
      }).then(res => {
        //请求成功，触发then中的函数
        if(res.data.code == 1 ){
          this.$notify({
            title: '提示',
            message:  "注册成功"
          });
         setTimeout(function(){
          that.$router.push("/Login")
         },1000)

        }else{
          this.$notify({
            title: '提示',
            message:  res.data.msg
          });
        }
      })
      .catch(error =>
        //请求失败，触发catch中的函数 可省略
        console.log(error)
      )
    }

        }
    }
</script>

<style lang="less" scoped>
  * {
    padding: 0px;
    margin: 0px
  }

  // 改变input框背景颜色
  /deep/ .el-input__inner {
    background-color: transparent !important;
    border: none;
    color: #ffffff;
    margin-top: 10px;
  }

  .login {
    display: flex;
    width: 100%;
    height: 100vh;
    // background: url(./images/登录.png);
    background: url(../../assets/imgs/登陆页/登录页背景.jpg);
    background-size: 100% 100%;
    perspective:1450px;
    .section{
      transform-style:preserve-3d;
    }
    .section img:nth-of-type(1){
      transform:rotateY(0deg);
    }
    .ico{
      width:5%;
      color:#33CCFF;
    }
    // 登录大盒子
    .loginform {
      // flex:1;
      // width: 500px;
      // height: 500px;
      width: 70%;
      height: 70%;
      // background-color: pink;
      float: right;
      margin-right: 15%;
      // margin-top: 200px;
      margin-top: 100px;
      .userbox{
        border:1px solid #0099CC;
        border-radius: 5px;
        background-image: radial-gradient(circle,rgba(0,0,51,.5) 45%,rgba(0,51,102,.6) , rgba(44,97,135,.8));
        /*opacity:0.7;*/
        padding:15% 8%;
        margin-top: 20px;
        /*padding-top: 50px;*/
      }

      button {
        width: 90%;
        height: 40px;
        background-color: #4C97F3;
        opacity: 1!important;
        border: none;
        border-radius: 25px;
        margin-top: 30px;
        margin-left: 30px;
        color: #fff;
      }

      .zhanghao {
        margin-top: 20px;
        text-align: center;
      }

      .zhuce {
        color: #4776FB;
      }

      .logintitle {
        width: 100%;
        height: 50px;
        // background-color: aqua;
        // color: rgba(71, 118, 251, 1);
        color: #33CCFF;
        font-size: 35px;
        font-weight: bold;
        text-align: center;
        font-family: SourceHanSansSC-regular;
      }
      .el-icon{
        color:#fff;
        font-size: 30px;
        margin-right: 10px;
      }
    }
  }
  /deep/ .el-tabs__item{
    font-size: 20px!important;
    line-height: 24px!important;
    color: white;
  }
  /deep/.el-tabs__nav-wrap::after{
    background: inherit!important;
  }
  /deep/ .is-active{
    color: #4F99F3!important;

  }
  @keyframes yuan {
    100% {
      box-shadow: 1px 1px 1px 5px rgba(255, 255, 255, 1) !important;
      transform: translateY(20px);

    }
  }
  @keyframes line {

    0% {
      /*opacity: 1;*/
      /*transform: translateY(0);*/

    }
    100% {
      /*opacity: .5;*/
      /*transform: translateY(-8%);*/
      transform: scale(1.1);
    }
  }
  @keyframes ico {

    0% {
      /*opacity: 1;*/
      /*transform: translateY(0);*/

    }
    100% {
      /*opacity: .5;*/
      transform: translateY(15px);
      /*transform: scale(1.1);*/
      box-shadow: 1px 1px 10px rgba(11, 12, 7,.3);

    }
  }
  .lines{
    animation: yuan 1s infinite ease-in-out alternate;
  }
  .yuan{
    animation: yuan 1s infinite ease-in-out alternate;
    position:absolute;top:27%; left:40%; width:20%;z-index: 4;
  }
  .line{
    animation: line 1s infinite ease-in-out alternate;
    position:absolute;top:30%; left:10%; width:80%;
  }
  .ico{
  }
  .a,.f{
    animation: ico 1s infinite ease-in-out alternate;
  }
  .b,.e{
    animation: ico 1s infinite ease-in-out alternate;
    animation-delay: 0.6s;
  }
  .c,.d{
    animation: ico 1s infinite ease-in-out alternate;
    animation-delay: 0.9s;
  }
</style>
